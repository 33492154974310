import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ReactTypingEffect from 'react-typing-effect';
import { Images } from '~data'
import Hero from "./style"
import HeroVideo from "../../../assets/video/arcpr-swirl-bg-crf25-flipped.mp4"

export default function HeroSection(){
return(
<Hero backgroundImage={Images.agency.heroImg} backgroundOverlay={Images.agency.heroImgOverlay}>
  <Container>
    <Row className="align-items-center justify-content-center justify-content-md-start">
      {/* Welcome content Area */}
      <Col className="col-xxl-6 col-xl-7 col-lg-8 col-md-8 col-xs-11 order-2 order-lg-1"> 
        <Hero.Content>          
          <Hero.Title as="h1">
            Do you have a communications 
            <br />
            
            <ReactTypingEffect
              text={["challenge", "opportunity"]}
              className="highlight-text d-inline-block text-primary no-bottom-margin"
              speed="150"
              eraseSpeed="100"
              typingDelay="400"
              eraseDelay="1600"
              cursorClassName="typed-cursor"
            />
            <br />
            to solve? 
          </Hero.Title>
          <Hero.Text>
            Harness the creative problem solving that 25 years of strategic communications experience delivers to audiences that matter to you – employees, media, community, government, investors, and social influencers.
          </Hero.Text>
        </Hero.Content>
      </Col>
      {/*/ .Welcome Content Area */}
    </Row>
  </Container>
  <Hero.Video>
    <video muted autoPlay="autoplay" loop>
      <source src={HeroVideo} type="video/mp4" />
    </video>
  </Hero.Video>
</Hero>
)
}