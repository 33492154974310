import React from "react";
import { PageWrapper } from "~components/Core";
import HeroSection from '~sections/agency/Hero'
import FeatureSection from '~sections/agency/Feature'
import ContentSectionOne from '~sections/agency/ContentOne'
import FooterFive from '~sections/agency/FooterFive'


const header = {
  headerClasses: "site-header site-header--menu-end dark-header",
  containerFluid:false,
  darkLogo:false,
}

export default function Agency() {
  return (
    <PageWrapper headerConfig={header}>
        <HeroSection/>
        <ContentSectionOne/>
        <FeatureSection/>
        <FooterFive/>
    </PageWrapper>
  )
}
