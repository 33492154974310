import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { StaticImage as Img } from 'gatsby-plugin-image'
import SectionTitle from './Components/SectionTitle'
import Feature from './style'
 
export default function FeatureSection(){
return(
<Feature>
  <Container>
    <Row className="align-items-center justify-content-center g-0">
      <Col xs="12" className="col-xxl-5 col-lg-5 col-md-10">
        <Feature.Image>
          <Img className="w-100" src="../../../assets/image/arc-symbol-3.png" alt="content" layout="fullWidth" placeholder="blurred"/>
        </Feature.Image>
      </Col>
      <Col className="col-xxl-7 col-lg-7 col-md-10">
        <Feature.Box>
          {/* Section Title */}
          <Feature.Content>
              <SectionTitle
              subTitle="Services" 
              title="Corporate Positioning, Messaging &amp; Awareness"
              text="At Arc PR &amp; Communications, we reach and build stronger connections with audiences that matter to your business or organization – clients, consumers, partners, media, government, social influencers. We leverage the power of impactful communications to articulate what you stand for and reinforce through integrated marketing and communications plans."
              subTitleProps={{mb:"10px",className:"subline",fontColor:"#FFC64A"}}
              titleProps={{mb:"10px",as:"h2"}}
              mb="69px" />
          </Feature.Content>
          {/*/ .Section Title */}
        </Feature.Box>
      </Col>
    </Row>

    <Row className="align-items-center justify-content-center g-0 r-reverse">
      <Col xs="12" className="col-xxl-5 col-lg-5 col-md-10">
        <Feature.Image mlLG="-7%">
          <Img className="w-100" src="../../../assets/image/arc-symbol-16.png" alt="content" layout="fullWidth" placeholder="blurred"/>
        </Feature.Image>
      </Col>
      <Col className="col-xxl-7 col-lg-7 col-md-10">
        <Feature.Box>
          {/* Section Title */}
          <Feature.Content>
              <SectionTitle
              subTitle="Services" 
              title="Executive Communications - Growing Leadership Effectiveness"
              text="Let us help you articulate and convey your vision, mission, purpose and inspire audiences inside and outside your organization. Arc PR &amp; Communications provides executive thought leadership strategy, planning and content execution. We coach leaders to be confident and on message, ensuring you’re ready for those big moments – on stage, on camera, or in a board meeting room."
              subTitleProps={{mb:"10px",className:"subline",fontColor:"#FFC64A"}}
              titleProps={{mb:"10px",as:"h2"}}
              mb="69px" />
          </Feature.Content>
          {/*/ .Section Title */}
        </Feature.Box>
      </Col>
    </Row>

    <Row className="align-items-center justify-content-center g-0">
      <Col xs="12" className="col-xxl-5 col-lg-5 col-md-10">
        <Feature.Image>
          <Img className="w-100" src="../../../assets/image/arc-symbol-17.png" alt="content" layout="fullWidth" placeholder="blurred"/>
        </Feature.Image>
      </Col>
      <Col className="col-xxl-7 col-lg-7 col-md-10">
        <Feature.Box>
          {/* Section Title */}
          <Feature.Content>
              <SectionTitle
              subTitle="Services" 
              title="Corporate Communications Impact &amp; Effectiveness"
              text="At Arc PR &amp; Communications, we reach and build stronger connections with audiences that matter to your business or organization – clients, consumers, partners, media, investors, government, social influencers. We leverage the power of impactful communications to articulate what you stand for and reinforce through integrated marketing and communications plans."
              subTitleProps={{mb:"10px",className:"subline",fontColor:"#FFC64A"}}
              titleProps={{mb:"10px",as:"h2"}}
              mb="69px" />
          </Feature.Content>
          {/*/ .Section Title */}
        </Feature.Box>
      </Col>
    </Row>
  </Container>
</Feature>


)
}