import styled from 'styled-components/macro';
import { Box, Heading,Button,Paragraph } from '~styled';

const Feature = styled(Box)`
    padding-top: 60px;
    padding-bottom: 60px;    

    .r-reverse {
        flex-direction: row-reverse;
    }

    @media (min-width:768px) {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @media (min-width:992px) {
        padding-top: 125px;
        padding-bottom: 130px;
    }
`

Feature.SubTitle = styled(Heading)`

`

Feature.Title = styled(Heading)`

`
 
Feature.Content = styled(Heading)`    
    background-color:#4c4c4c;
    padding-left: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 30px;
    z-index:-1;
    @media (min-width: 576px){
        padding-left: 80px;
        padding-top: 80px;
        padding-bottom: 80px;
        padding-right: 80px;
    }
    @media (min-width: 992px){
        padding-left: 50px;
        padding-top: 100px;
        padding-bottom: 100px;
        padding-right: 30px;
    }
    @media (min-width: 1200px){
        padding-left: 110px;
        padding-top: 145px;
        padding-bottom: 145px;
        padding-right: 90px;
    }

    h2 {
        font-size: 32px;   
    }
`

Feature.Text = styled(Paragraph)`

`

Feature.Image = styled(Box)`
z-index: 1;
border-radius: 5px;
position: relative;
opacity: 0.75;
@media (min-width: 992px){
    width: 107%;
}
`

Feature.Box = styled(Box)`

`

export default Feature;