import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { StaticImage as Img } from 'gatsby-plugin-image'
import Content from './style'
import SectionTitle from './Components/SectionTitle'
 
export default function ContentSectionOne(){
return(
<Content>
  <Container>
    <Content.InnerWrapper>
      <Row className="align-items-center justify-content-center" id="services">
        <Col xs="12" className="col-lg-4 col-md-8 col-xs-11 text-center text-lg-start v-center">
          {/* Section Title */}
          <SectionTitle 
            subTitle="How We Add Value" 
            title="above and beyond"
            text="Listen, collaborate and develop communications strategies that build and protect your brand, inspire your people, and grow your business. "
            titleProps={{mb:"30px"}}
            subTitleProps={{mb:"25px",fontColor:"#FFC64A"}}
          />
          <a href="mailto:simon.townsend@arcpr.ca">
            <Content.Button background="#308997" rounded={false} mt="25px">
              Contact Us
            </Content.Button>
          </a>
        </Col>
        <Col xs="12" className="col-xxl-5 col-lg-4 col-md-6 col-xs-9">
          <Content.Image mt="30px" mb="30px" mbMD="0" mtMD="0">
            <Img 
              src="../../../assets/image/arc-symbol-9.png" 
              alt="content" 
              layout="fullWidth" 
              placeholder="blurred"
              objectFit="cover"
              />
          </Content.Image>
        </Col>
        <Col xs="12" className="col-xl-3 col-lg-4 col-md-6 col-xs-9 order-3">
          <Content.WidgetsWrapper mb="-10px" mtMD="40px" mtLG="0" mrXS="70px" mrMD="30px" mrLG="0">
            {/* Single Services */}
            <Content.Widget pb="10px">
              <Content.WidgetTitle>Experience</Content.WidgetTitle>
              <Content.WidgetText>25 years in communications leadership roles at leading brands including RBC, Sun Life Financial, TD Bank, Softchoice, FleishmanHillard HighRoad.</Content.WidgetText>
            </Content.Widget>
            {/*/ .Single Services */}
            {/* Single Services */}
            <Content.Widget pb="10px">
              <Content.WidgetTitle>Expertise</Content.WidgetTitle>
              <Content.WidgetText>Broad and diverse mix of large public company and agency experience.</Content.WidgetText>
            </Content.Widget>
            {/*/ .Single Services */}
          </Content.WidgetsWrapper>
        </Col>
      </Row>
    </Content.InnerWrapper>
  </Container>
</Content>

)
}